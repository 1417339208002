import React, { useEffect, useRef } from 'react';
import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import {
  StaticImage,
  GatsbyImage,
  getImage,
} from 'gatsby-plugin-image';

import { kebabCase } from 'lodash';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import TitlePage from '../../components/TitlePage';
import Anchorlink from '../../components/Anchorlink';
import * as S from '../styled';
import WrapperPage from '../../components/WrapperPage';
import WrapperSection from '../../components/WrapperSection';
import PageImage from '../../components/PageImage';
import Breadcrumbs from '../../components/Breadcrumbs';

const BlogPostTemplate = ({
  body,
  tags,
  title,
  slug,
  featuredimage,
}) => {
  const blogPostViewItems = useRef([]);
  blogPostViewItems.current = [];
  const addToRefs = (el) => {
    if (el && !blogPostViewItems.current.includes(el)) {
      blogPostViewItems.current.push(el);
    }
  };
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(blogPostViewItems.current, { autoAlpha: 0, y: 70 });
    ScrollTrigger.batch(blogPostViewItems.current, {
      start: 'top bottom-=100px',
      // markers: true,
      onEnter: (batch) =>
        gsap.to(batch, {
          duration: 1.5,
          autoAlpha: 1,
          y: 0,
          ease: Power4.easeOut,
          stagger: 0.3,
          delay: 0.3,
        }),
    });
  }, []);

  const image = getImage(featuredimage.src);
  return (
    <WrapperPage>
      <PageImage>
        <StaticImage
          src="../../assets/images/http.jpg"
          alt="Blog"
          style={{ width: '100%', height: '100%', opacity: 0.2 }}
          layout="fullWidth"
          objectPosition="center 65%"
          quality={85}
          imgStyle={{ filter: 'grayscale()' }}
        />
      </PageImage>
      <WrapperSection>
        <Breadcrumbs
          category="Blog"
          categoryHref="blog"
          post={title}
          postHref={`blog${slug}`}
        />
        <S.PostContainer>
          <TitlePage text={title} />
          <div ref={addToRefs} className="post-body">
            <S.ImageContainer>
              <GatsbyImage
                image={image}
                alt={featuredimage.alt}
                object-fit="contain"
                width="100%"
                style={{
                  margin: `0 auto`,
                }}
              />
            </S.ImageContainer>
            <MDXRenderer>{body}</MDXRenderer>
          </div>
          {tags && tags.length ? (
            <S.TagsContainer ref={addToRefs}>
              <S.TagsTitle>Tags</S.TagsTitle>
              <S.TagsListOnPost>
                {tags.map((tag) => (
                  <S.ListItem key={`${tag}tag`}>
                    <Anchorlink
                      ariaLabel="tag anchor"
                      to={`/tags/${kebabCase(tag)}/`}
                    >
                      {tag}
                    </Anchorlink>
                  </S.ListItem>
                ))}
              </S.TagsListOnPost>
            </S.TagsContainer>
          ) : null}
        </S.PostContainer>
      </WrapperSection>
    </WrapperPage>
  );
};

BlogPostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BlogPostTemplate;
