import * as React from 'react';
import { graphql } from 'gatsby';
// import { useTranslation } from 'react-i18next';
// import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import BlogPostTemplate from './blogPostTemplate';

const BlogTemplate = ({ data, pageContext }) => (
  <Layout>
    <BlogPostTemplate
      body={data.mdx.body}
      description={data.mdx.frontmatter.description}
      tags={data.mdx.frontmatter.tags}
      title={data.mdx.frontmatter.title}
      slug={data.mdx.frontmatter.slug}
      featuredimage={data.mdx.frontmatter.featuredimage}
      pageContext={pageContext}
    />
  </Layout>
);

export default BlogTemplate;

export const Head = ({ data }) => (
  <SEO
    title={`${data.mdx.frontmatter?.title} - Giedeé Informatyka`}
    description={`${data.mdx.frontmatter.description}`}
  />
);

export const query = graphql`
  query ($locale: String!, $slug: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        slug
        title
        tags
        date
        description
        featuredimage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 85
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      body
    }
  }
`;
